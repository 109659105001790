import React from 'react';
import ContactItem from './ContactItem';
import SocialItem from './SocialItem';

import callIcon from '../../../resources/images/call-icon.svg';
import emailIcon from '../../../resources/images/mail-icon.svg';
import tiktok from '../../../resources/images/social/tiktok.svg';
import youtube from '../../../resources/images/social/youtube.svg';
import linkenin from '../../../resources/images/social/linkenin.svg';
import instagram from '../../../resources/images/social/instagram.svg';

const Contact = () => (
  <div className="contact">
    <div className="contact__info">
      <ContactItem icon={callIcon} title="Have a question?" desc="+1-888-231-7337" />
      <ContactItem icon={emailIcon} title="Contact us at" desc="support@takeapeeq.com" />
    </div>
    <p className="contact__description">
      Track with Precision. Secure with Confidence.
      Have Peace of Mind.
    </p>
    <p className="contact__follow">
      Follow Us
    </p>
    <div className="contact__social">
      <SocialItem icon={tiktok} link="https://www.tiktok.com/@takeapeeq" title="" />
      <SocialItem icon={linkenin} link="https://www.linkedin.com/company/takeapeeq/" title="" />
      <SocialItem icon={youtube} link="https://www.youtube.com/@takeapeeq" title="" />
      <SocialItem icon={instagram} link="https://www.instagram.com/peeqgps/ " title="" />
    </div>
  </div>
);

export default Contact;
