import React from 'react';

const SocialItem = ({ icon, link, title }) => (
  <div className="social-item">
    <a href={link} title={title} target="_blank" rel="noreferrer">
      <img src={icon} alt={title} width="32" height="32" />
    </a>
  </div>
);

export default SocialItem;
